import React from "react";

function Preloader() {
  return (
    <div className="preloader">
      <div className="spinner-dots">
        <span className="dot1"></span>
        <span className="dot2"></span>
        <span className="dot3"></span>
      </div>
    </div>
  );
}
export default Preloader;
